import React from 'react'
import { useState } from 'react'
import BusinessBillHeroImg from '../../assets/images/BusinessBillHeroImg.jpg'
import WaysToPayCardImage from '../../assets/images/WaysToPayImg.avif'
import HomeServiceIcon from "../../assets/images/HomeServiceChargesIcon.svg"
import WaytoPayCrdImg from '../../assets/images/WaysToPayCardImg.jpg'
import PaymentLocationCardImg from '../../assets/images/PaymentLocationCardImg.jpg'
import AddServiceCardImg from '../../assets/images/AdditionalServicesCardImg.avif'
import SocalGasBillImg from '../../assets/images/SocalGasBill.jpg'
import UnderstandBillicon from '../../assets/images/UnderstandMyBillIcon.svg'
import { Link } from "react-router-dom";


const BusinessBilling = () => {
  const faqData = [
    {
      question: "How does my natural gas bill get calculated?",
      answer:
        "Our rates are regulated by the California Public Utilities Commission (CPUC) and are based on three components:\n\n1) Commodity Costs - The cost of the natural gas itself.\n2) Transportation Costs - The cost of natural gas delivery.\n3) Public Purpose Surcharge - The cost to fund natural gas-related programs.",
    },
    {
      question: "Can I get a discount on my bill?",
      answer:
        "SoCalGas® offers a 20% discount through the CARE program for income-qualified customers. Check our CARE guideline information to see if you qualify.",
    },
    {
      question: "What payment options are available?",
      answer:
        "We offer various payment options, including direct debit, credit card, and bank transfer.",
    },
    {
      question: "Can I pay my bill online?",
      answer:
        "You can make payments online through My Account. If you are already registered for My Account, you can access your account by logging in. If you are not yet registered, you can easily register at 'Register for My Account.'",
    },
    {
      question: "How do I know if I qualify for the CARE Program?",
      answer:
        "SoCalGas® offers a 20% discount through the CARE program for income-qualified customers. Check our CARE guideline information to see if you qualify.",
    },
  ];
  

  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <>

    {/* Business Billing First container */}

    <div className="BusinessBill-first-container container-fluid mt-2 first-container">
  <div className="BusinessBill_hero_img position-relative">
    <img
      src={BusinessBillHeroImg}
      alt="Service Hero"
      className="img-fluid w-100"
      style={{ borderRadius: "5px" }}
    />
    <h1 className="BusinessBill-hero-heading">Business Billing</h1>
  </div>
</div>

{/* BusinessBilling-second-Container */}

<div className="BusinessBill-five-container container BusinessBill-container mt-5">
        <h1 className="mb-5 billing-h1"> Ways To Pay</h1>
        <div className="row BusinessBill-section">
          <div className="col-md-7">
            <img
              src={WaysToPayCardImage}
              alt="assistance progress"
              className="BusinessBill-img img-fluid border border-primary rounded h-100"
            />
          </div>
          <div className="col-md-5 mt-sm-5 mt-md-0 mt-4 px-0">
            <div className="card h-100 text-center">
              <div
                className="card-body px-3"
                style={{
                  backgroundColor: "rgb(216, 229, 240)",
                  borderRadius: "10px",
                }}
              >
                <h1 className="mt-sm-1 mt-lg-4 text-center">
                  <img
                    src={HomeServiceIcon}
                    alt="Energy Savings Tips and Tools"
                    className="img-fluid"
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "contain",
                    }}
                  />
                </h1>
                <h4 className="card-text text-secondary mt-sm-1 mt-lg-3">
                Payment Options Made Easy
                </h4>
                <p className="text-secondary mt-sm-1 mt-lg-4">
                Managing your bill has never been simpler. With a variety of payment methods available, you can choose the one that best fits your schedule and preferences. Whether you're paying online, by phone, or setting up automatic payments, each option is secure and convenient. 
                </p>
                <div className="d-flex justify-content-center mt-sm-1 mt-md-3 mt-lg-4 Billing-btn">
                  <Link to={"/ways-To-Pay"}><div className="mt-auto">
                    <a href="#" className="">
                      Learn more
                    </a>
                  </div></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


{/* BusinessBill-third-container */}
<div className="BusinessBill-third-container container mt-5">
  <h1 className="billing-h1">Manage Your Payments</h1>  
  <div className="row text-center mt-5">
    {/* Card 1 */}
    <div className="col-md-6 col-lg-4 mt-4"> {/* Change mt-sm-5 to mt-4 */}
      <div className="card custom-card flex-fill">
        <img src={WaytoPayCrdImg} className="card-img-top" alt="Ways to Pay" />
        <div className="card-body" style={{
                  backgroundColor: "rgb(216, 229, 240)"
                }}>
          <h4 className="card-title billing-h1">Ways to Pay</h4>
          <p className="card-text text-secondary">Discover flexible payment methods that suit your needs, whether you prefer online transactions, autopay, or manual payments.</p>
          <div className="d-flex justify-content-center mt-4 Billing-btn">
                  <div className="mt-auto">
                    <a href="#" className="">
                      Learn more
                    </a>
                  </div>
                </div>  
        </div>
      </div>
    </div>
    
    {/* Card 2 */}
    <div className="col-md-6 col-lg-4 mt-4"> {/* Change mt-sm-5 to mt-4 */}
      <div className="card custom-card flex-fill">
        <img src={PaymentLocationCardImg} className="card-img-top" alt="Payment Locations" />
        <div className="card-body" style={{
                  backgroundColor: "rgb(216, 229, 240)"
                }}>
          <h4 className="card-title billing-h1">Payment Locations</h4>
          <p className="card-text text-secondary">Find nearby authorized locations where you can make your payments in person quickly and securely.</p>
          <div className="d-flex justify-content-center mt-4 Billing-btn">
                  <div className="mt-auto">
                    <a href="#" className="">
                      Learn more
                    </a>
                  </div>
                </div>
        </div>
      </div>
    </div>

    {/* Third card */}
    <div className="col-md-6 col-lg-4 mt-4"> 
      <div className="card custom-card flex-fill">
        <img src={AddServiceCardImg} className="card-img-top" alt="Additional Services" />
        <div className="card-body" style={{
                  backgroundColor: "rgb(216, 229, 240)"
                }}>
          <h4 className="card-title billing-h1">Additional Services</h4>
          <p className="card-text text-secondary">Explore extra services like bill alerts and budget management tools to keep track of your energy usage and payments.</p>
          <div className="d-flex justify-content-center mt-4 Billing-btn">
                  <div className="mt-auto">
                    <a href="#" className="">
                      Learn more
                    </a>
                  </div>
                </div>
        </div>
      </div>
    </div>  
  </div>
</div>

{/* BusinessBill-four-container */}

<div className="BusinessBill-four-container container BusinessBill-container mt-5">
        <h1 className="mb-5 billing-h1">Navigate Your Billing Statement</h1>
        <div className="row BusinessBill-section">
          <div className="col-md-7">
            <img
              src={SocalGasBillImg}
              alt="assistance progress"
              className="BusinessBill-img img-fluid border border-primary rounded h-100"
            />
          </div>
          <div className="col-md-5 mt-sm-5 mt-md-0 mt-4 px-sm-0">
            <div className="card h-100 text-center">
              <div
                className="card-body px-3"
                style={{
                  backgroundColor: "rgb(216, 229, 240)",
                  borderRadius: "10px",
                }}
              >
                <h1 className="mt-sm-1 mt-lg-4 text-center">
                  <img
                    src={UnderstandBillicon}
                    alt="Energy Savings Tips and Tools"
                    className="img-fluid"
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "contain",
                    }}
                  />
                </h1>
                <h4 className="card-text text-secondary mt-sm-1 mt-lg-3">
                Understanding Your Invoice
                </h4>
                <p className="text-secondary mt-sm-1 mt-lg-4">
                Understanding your bill is essential to managing your energy costs effectively. This section provides a detailed breakdown of your charges, helping you decipher each component of your bill. You will find information about the rates applied to your energy usage, as well as any fixed fees associated with your account. 
                </p>
                <div className="d-flex justify-content-around mt-sm-1 mt-md-3 mt-lg-4 Billing-btn">           
                  <Link to={"/understand-MyBill"}><div className="mt-auto">
                    <a href="#" className="">
                      Learn more
                    </a>
                  </div></Link>
                  <a href="#" className="">
                     View My Bill    
                    </a>
                </div>               
              </div>
            </div>
          </div>
        </div>
      </div>

{/* BusinessBill-five-container */}

<div className="BusinessBill-container container mt-5">
      <h1 className="billing-h1">Frequently Asked Questions</h1>

      <div className="BusinessBill-faq-container container BusinessBill-five-container mt-3 p-4">
        <div className="BusinessBill-faq-header"></div>
        
        {faqData.map((faq, index) => (
          <div
            className={`BusinessBill-faq-item ${activeIndex === index ? "active" : ""}`}
            key={index}
          >
            <button
              className="BusinessBill-faq-question"
              onClick={() => handleClick(index)}
            >
              {faq.question}
              <span className="BusinessBill-faq-icon">
                {activeIndex === index ? "-" : "+"}
              </span>
            </button>
            <div
              className={`BusinessBill-faq-answer ${activeIndex === index ? "show" : ""}`}
            >
              {/* Split the answer by line breaks for proper rendering */}
              {faq.answer.split('\n').map((line, i) => (
                <span key={i}>
                  {line}
                  <br />
                </span>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>


      
    </>
  )
}

export default BusinessBilling

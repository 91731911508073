import React, { useState, useEffect } from 'react';
import './css/app.css';

const testimonials = [
  {
    text: "I just joined this new team in October and my co-workers are already telling me to get my certificate in instructional design so that I can keep moving up. People at the company want you to succeed and improve your life. I really think it's an amazing place.",
    name: "La Dasha Morris"
  },
  {
    text: "Breaking barriers and creating pathways drives me. I am inspired by the thought of leaving footprints for other women to follow. I have had, and continue to have, great leaders and counterparts.",
    name: "Jennifer Haro"
  },
  {
    text: "One of the benefits of working at SoCalGas is that you get to work in an exciting space as we lead the way in innovation around safety, clean energy, and sustainability.",
    name: "Carol Mak"
  },
  {
    text: "I joined SoCalGas for the opportunity to serve my community and grow in a thriving, sustainable organization.",
    name: "DJ Shoemate"
  },
  {
    text: "I believe this company is one of the best places to learn and grow professionally.",
    name: "Chris Harris"
  }
];

const Testimonials = () => {
  const [current, setCurrent] = useState(0);

  // Move to the next testimonial (slide one at a time)
  const nextSlide = () => {
    setCurrent((current + 1) % testimonials.length);
  };

  // Move to the previous testimonial
  const prevSlide = () => {
    setCurrent((current - 1 + testimonials.length) % testimonials.length);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 4000); // Automatically slide every 3 seconds
    return () => clearInterval(interval);
  }, [current]);

  return (
    <div className="testimonial-section">
      <h2 className="testimonial-heading">TESTIMONIALS</h2>
      <p className="testimonial-subheading">What Our Employees Say</p>

      <div className="testimonial-slider">
        <button onClick={prevSlide} className="testimonial-arrow left-arrow">{'<'}</button>

        <div className="testimonial-wrapper">
          {testimonials.slice(current, current + 3).map((testimonial, index) => (
            <div
              className={`testimonial ${index === current ? 'active' : ''}`}
              key={index}
            >
              <div className="testimonial-content">
                <div className="testimonial-avatar">
                  <img
                    src={`https://via.placeholder.com/100`}   
                    alt={`Avatar of ${testimonial.name}`}
                  />
                </div>
                <p className="testimonial-text">"{testimonial.text}"</p>
                <p className="testimonial-name">{testimonial.name}</p>
              </div>
            </div>
          ))}
        </div>

        <button onClick={nextSlide} className="testimonial-arrow right-arrow">{'>'}</button>
      </div>
    </div>
  );
};

export default Testimonials;

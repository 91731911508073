import { useState } from "react";

import "./Billing.css";
import Home_banking from "../../assets/images/home_banking.svg";
import By_mail from "../../assets/images/By_mail.svg";
import By_phone from "../../assets/images/By_phone.svg";
import Credit_Debit_card from "../../assets/images/Credit_Debit.svg";
import Direct_debit from "../../assets/images/Direct_debit.svg";
import Pay_in_person from "../../assets/images/Pay_to_person.svg";
import { Link } from "react-router-dom";
import Hero_img from "../../assets/images/hero_woman-sitting-on-couch-with-phone_1856-410.jpg";
import Assist_Team from "../../assets/images/assist-program-team.svg";
import UnderstandHiBill from "../../assets/images/Understand_HiBill.svg";
import Assitance_prog from "../../assets/images/assist-four-containerCardImg.jpg";
import ManageHighBillCardImg from "../../assets/images/assist-five-CardImg.jpg";
export default function Ways_to_pay() {
  const [activeQuestion, setActiveQuestion] = useState(null);

  const toggleQuestion = (index) => {
    setActiveQuestion(activeQuestion === index ? null : index);
  };
  const faqData = [
    {
      question: "What is the billing process?",
      answer:
        "Our billing process involves reading your meter, calculating usage, and sending a bill based on your consumption.",
    },
    {
      question: "How can I track my energy usage?",
      answer:
        "You can track your energy usage through our online dashboard or mobile app.",
    },
    {
      question: "What payment options are available?",
      answer:
        "We offer various payment options including direct debit, credit card, and bank transfer.",
    },
    {
      question: "Can I set up payment alerts?",
      answer:
        "Yes, you can set up email or SMS alerts to get reminders about your bill payments.",
    },
    {
      question: "How do I change my account details?",
      answer:
        "To change your account details, log in to your account or contact our support team for assistance.",
    },
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <>
      {/* waysToPay-first-container */}
      <div className="waysToPay-first-container container-fluid mt-2 first-container">
        <div className="service_hero_img position-relative">
          <img
            src={Hero_img}
            alt="Service Hero"
            className="img-fluid w-100"
            style={{ borderRadius: "5px" }}
          />
          <h1 className="waysToPay-hero-heading text-start">
            Billing And Payments
          </h1>
        </div>
      </div>

      {/* ways-to-pay-Second-container */}

      <div className="waysToPay-second-container container mt-5 way-to-pay-container">
        <h1 className="billing-h1">Ways to Pay</h1>
        <div className="ways-to-pay-row row mt-4 g-4">
          <div className="col-md-4 d-flex justify-content-center">
            <div className="card h-100">
              <div className="card-body d-flex flex-column text-center">
                <p>
                  <img
                    src={Home_banking}
                    alt="Home Banking Icon"
                    style={{
                      width: "50px",
                      height: "50px",
                      objectFit: "contain",
                    }}
                  />
                </p>
                <h5 className="card-title">Home Banking</h5>
                <p className="card-text">
                  You can pay your bills online through your bank, credit union,
                  brokerage firm, or another financial institution using your
                  checking or savings account.
                </p>
                <div className="mt-auto Billing-btn">
                  <a href="#" className="mt-3">
                    Learn more
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 d-flex justify-content-center">
            <div className="card h-100">
              <div className="card-body d-flex flex-column text-center">
                <p>
                  <img
                    src={By_mail}
                    alt="By Mail Icon"
                    style={{
                      width: "50px",
                      height: "50px",
                      objectFit: "contain",
                    }}
                  />
                </p>
                <h5 className="card-title">By Mail</h5>
                <p className="card-text">
                  To pay by mail, send a check or money order along with your
                  bill stub to SoCalGas P.O. Box C Monterey Park, CA 91756.
                </p>
                <div className="mt-auto  Billing-btn">
                  <a href="#" className="mt-3">
                    Learn more
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 d-flex justify-content-center">
            <div className="card h-100">
              <div className="card-body d-flex flex-column text-center">
                <p>
                  <img
                    src={By_phone}
                    alt="By Phone Icon"
                    style={{
                      width: "50px",
                      height: "50px",
                      objectFit: "contain",
                    }}
                  />
                </p>
                <h5 className="card-title">By Phone</h5>
                <p className="card-text">
                  Call our toll-free number at 1-877-238-0092 to authorize a
                  payment from your checking account at no charge.
                </p>
                <div className="mt-auto  Billing-btn">
                  <a href="#" className="mt-3">
                    Learn more
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 d-flex justify-content-center">
            <div className="card h-100">
              <div className="card-body d-flex flex-column text-center">
                <p>
                  <img
                    src={Credit_Debit_card}
                    alt="Credit/Debit Card Icon"
                    style={{
                      width: "50px",
                      height: "50px",
                      objectFit: "contain",
                    }}
                  />
                </p>
                <h5 className="card-title">Debit or Credit Card</h5>
                <p className="card-text">
                  To pay by credit/debit cards or electronic checks, you must
                  use BillMatrix, an independent service provider. A $1.50
                  convenience fee is charged.
                </p>
                <div className="mt-auto  Billing-btn">
                  <a href="#" className="mt-3">
                    Learn more
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 d-flex justify-content-center">
            <div className="card h-100">
              <div className="card-body d-flex flex-column text-center">
                <p>
                  <img
                    src={Direct_debit}
                    alt="Direct Debit Icon"
                    style={{
                      width: "50px",
                      height: "50px",
                      objectFit: "contain",
                    }}
                  />
                </p>
                <h5 className="card-title">Direct Debit</h5>
                <p className="card-text">
                  Enjoy effortless payments! Your monthly payment is deducted
                  from your checking account at no charge.
                </p>
                <div className="mt-auto  Billing-btn">
                  <a href="#" className="mt-3">
                    Learn more
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 d-flex justify-content-center">
            <div className="card h-100">
              <div className="card-body d-flex flex-column text-center">
                <p>
                  <img
                    src={Pay_in_person}
                    alt="Pay in Person Icon"
                    style={{
                      width: "50px",
                      height: "50px",
                      objectFit: "contain",
                    }}
                  />
                </p>
                <h5 className="card-title">Pay in Person</h5>
                <p className="card-text">
                  SoCalGas partners with local businesses for quick and
                  convenient bill payments. Find a nearby authorized payment
                  location!
                </p>
                <div className="mt-auto Billing-btn">
                  <a href="#" className="mt-3">
                    Learn more
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ways-to-pay third container*/}

      <div className="waysToPay-third-container container">
        <h1 className=" mt-5 mb-5  billing-h1">Understand Your Bill</h1>
        <div className="row justify-content-center">
          {" "}
          {/* Added justify-content-center to center the column */}
          <div className="col-md-9 text-center ">
            {" "}
            {/* Added text-center for centering the video */}
            <iframe
              className="bill-vdo border border-dark"
              width="100%"
              height="500"
              src="https://www.youtube.com/embed/koysG6WdErI"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>

      {/* <div className="container mt-4 mb-5">
        <h2 className="my-4" style={{ textAlign: "center", color: "#002366" }}>
          Our Global Network
        </h2>
        <div className="row">
          <div className="col">
            <MapContainer
              center={[20, 0]}
              zoom={2}
              style={{ height: "600px", width: "100%" }}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' className="image-fluid"
              />
              {networkPoints.map((point, index) => (
                <Marker
                  key={index}
                  position={[point.lat, point.lng]}
                  icon={redDotIcon}
                >
                  <Tooltip>{point.name}</Tooltip>
                </Marker>
              ))}
            </MapContainer>
          </div>
        </div>
      </div> */}

      {/* wayt to pay fourth container*/}

      <div className="waysToPay-four-container container assist-container mt-5">
        <h1 className="mb-5 billing-h1">Assistance Programs</h1>
        <div className="row assistance-prog">
          <div className="col-md-7">
            <img
              src={Assitance_prog}
              alt="assistance progress"
              className="assist-prog-img img-fluid border border-md border-primary rounded"
            />
          </div>
          
          <div className="col-md-5 py-sm-0 px-sm-2 mt-sm-4 mt-md-0">
            <div className="card h-100 text-center">
              <div
                className="card-body px-3"
                style={{
                  backgroundColor: "rgb(216, 229, 240)",
                  borderRadius: "10px",
                }}
              >
                <div className="text-center">
                  <img
                    src={Assist_Team}
                    alt="Energy Savings Tips and Tools"
                    className="img-fluid"
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "contain",
                    }}
                  />
                </div>
                <h4 className="card-text text-secondary mt-sm-0 mt-md-0">
                  If you’re having trouble paying your gas bill, we have options
                  to help.
                </h4>
                <p className="text-secondary mt-sm-1 mt-md-2 mt-lg-4">
                  Explore our energy-saving upgrades, savings for those with
                  qualifying health conditions, assistance with past due bill
                  forgiveness, a 20% monthly discount through the CARE program,
                  and much more.
                </p>
                <div className="d-flex justify-content-center mt-sm-2 mt-md-1 mt-lg-3 mt-xl-5 Billing-btn">
                  <Link to={"/assistance-program"}>
                    <div className="mt-auto">
                      <a href="#" className="">
                        Learn more
                      </a>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ways to pay five container */}

      <div className="waysToPay-five-container container mt-5">
        <h1 className="mb-5 billing-h1">Manage Higher Bills</h1>
        <div className="row">
        <div className="col-md-5 py-sm-0 px-sm-2 mt-sm-4 mt-md-0">
  <div className="card h-100 text-center">
    <div
      className="card-body px-3"
      style={{
        backgroundColor: "rgb(216, 229, 240)",
        borderRadius: "10px",
      }}
    >
      <h1 className="mt-2 text-center mt-3">
        <img
          src={UnderstandHiBill}
          alt="Energy Savings Tips and Tools"
          className="img-fluid"
          style={{
            width: "100px",
            height: "100px",
            objectFit: "contain",
          }}
        />
      </h1>
      <h4 className="card-text text-secondary mive mt-md-3">
        Need Assistance with Your Gas Bill? Explore Your Options
      </h4>
      <p className="text-secondary mt-sm-1 mt-md-2 mt-lg-4">
      Facing challenges with your gas bill? We offer various solutions to ease your financial burden. From energy-saving programs to bill assistance, explore the support available to you.
      </p>
      <div className="d-flex justify-content-center mt-sm-2 mt-md-2 mt-lg-3 mt-xl-5 Billing-btn">
        <Link to={"/managehigh-bill"}>
          <div className="mt-auto">
            <a href="#" className="mt-3">
              Learn more
            </a>
          </div>
        </Link>
      </div>
    </div>
  </div>
</div>

          <div className="col-md-7 mt-sm-5 mt-md-0 mt-4">
            <img
              src={ManageHighBillCardImg}
              alt="assistance progress"
              className="manageHiBill-img img-fluid border border-md border-primary rounded"
            />
          </div>
        </div>
      </div>

      {/* waysToPay-six-container */}

      <div className="waysToPay-container container mt-5">
        <h1 className="billing-h1">Frequently Asked Questions</h1>
      </div>

      <div className="waysToPay-faq-container container waysToPay-five-container mt-3 p-4">
        <div className="waysToPay-faq-header"></div>
        {faqData.map((faq, index) => (
          <div
            className={`waysToPay-faq-item ${
              activeIndex === index ? "active" : ""
            }`}
            key={index}
          >
            <button
              className="waysToPay-faq-question"
              onClick={() => handleClick(index)}
            >
              {faq.question}
              <span className="waysToPay-faq-icon">
                {activeIndex === index ? "-" : "+"}
              </span>
            </button>
            <div
              className={`waysToPay-faq-answer ${
                activeIndex === index ? "show" : ""
              }`}
            >
              {faq.answer}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

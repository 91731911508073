import React, { useState } from 'react';
import './css/app.css';
import career from '../assets/images/career-card.png';
import faq_symbol from '../assets/images/faq-symbol.png';
import Testimonials from './Testimonials';

const Careers = () => {
  const [activeTab, setActiveTab] = useState('hiring-process');
  const [expandedFaq, setExpandedFaq] = useState(null);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setExpandedFaq(null); // Reset FAQ state when switching tabs
  };

  const handleFaqClick = (index) => {
    setExpandedFaq(expandedFaq === index ? null : index);
  };

  // Define FAQs for each tab
  const hiringProcessFaqs = [
    {
      question: '1. Application',
      answer: 'Our online application consists of submitting your resume and providing information about yourself including employment history, education, and work authorization...'
    },
    {
      question: '2. Resume Review & Assessments',
      answer: 'HR reviews resumes and applications against the minimum qualifications for the job. Resumes that meet the minimum qualifications are then sent to the hiring manager for review...'
    },
    {
      question: '3. Interview',
      answer: 'If your resume is chosen, you will be contacted by phone or email to schedule an interview. Interviews may be conducted virtually or on-site...'
    },
    {
      question: '4. Background Checks',
      answer: 'All offers of employment from SoCalGas are contingent upon candidates passing a background check, including a drug screen...'
    },
    {
      question: '5. You are Hired',
      answer: 'On your first day, a member of the onboarding team will be at the designated location to welcome you...'
    }
  ];

  const disabilityAccommodationsContent = `
    We want our application process to be accessible for everyone.
    If you need additional assistance, please contact our HR department.
    A team member can address any questions regarding special accommodations.

    Contact Information:
    Phone: (213) 244-3100
    Email: SoCalGas@hrdept.com
  `;

  const generalFaqs = [
    {
      question: 'How can I check the status of my application?',
      answer: 'From the Careers Page, click on "Apply Today", which will route you to our applicant tracking system...'
    },
    {
      question: 'How do I know if my application was submitted properly?',
      answer: 'Our application is a two-step process. The first step is to create a profile and upload a resume...'
    },
    {
      question: 'Do I have to submit a resume?',
      answer: 'Yes, the first part of the application process requires you to upload a resume and create a profile in the system...'
    },
    {
      question: 'How can I be notified of future opportunities?',
      answer: 'SoCalGas is featured on a variety of online job platforms, such as LinkedIn and Indeed. You can set up alerts...'
    },
    {
      question: 'What benefits does SoCalGas offer?',
      answer: 'SoCalGas offers a wide variety of benefits...'
    },
    {
      question: 'Do I have to live in Southern California to work for SoCalGas?',
      answer: 'Yes, employees are required to live in Southern California. Exceptions are very rare and based solely on business necessity...'
    }
  ];

  // Renders the FAQ accordion
  const renderFaqAccordion = (faqs) => {
    return faqs.map((faq, index) => (
      <div key={index} className="faq-item">
        <div className="faq-header">
          {/* Clickable icon for toggling the FAQ */}
          <span className="faq-toggle-icon" onClick={() => handleFaqClick(index)}>
            {expandedFaq === index ? '-' : '+'}
          </span>
          {/* The question text */}
          <span className="faq-question">{faq.question}</span>
        </div>
        {/* Show answer if the FAQ is expanded */}
        {expandedFaq === index && (
          <div className="faq-answer">
            <p>{faq.answer}</p>
          </div>
        )}
      </div>
    ));
  };

  return (
    <div className="careers-page">
      {/* Banner Section */}
      <section className="careers-banner">
        <div className="banner-content"></div>
      </section>

      {/* Career Info Section */}
      <section className="careers-info">
        <div className="careers-card">
          <div className="careers-text">
            <h2>Careers</h2>
            <p>
              Our employees have created a culture that involves safety, service,
              community, diversity, and innovation. Learn more about career
              opportunities and programs.
            </p>
            <button className="search-openings">Search Openings</button>
          </div>
          <div className="careers-image">
            <img src={career} alt="Career Image" />
          </div>
        </div>
      </section>

      <section className="careers-info">
        <div className="careers-card">
          <div className="careers-image">
            <img src={career} alt="Career Image" />
          </div>
          <div className="careers-text-similar">
            <h2> Internship Programs </h2>
            <p>
              Inspiring future generations of talented individuals who will improve energy systems is one way we shape the future. Our internship programs offer rich work experiences, rewarding peer-to-peer connections, and great networking opportunities.
            </p>
            <button className="search-openings">Explore Opportunities</button>
          </div>
        </div>
      </section>

      <Testimonials/>

      {/* FAQ Section */}
      <section className='faq-section'>
        {/* Tabs Section */}
        <div className="tabs">
          <button
            className={activeTab === 'hiring-process' ? 'active' : ''}
            onClick={() => handleTabClick('hiring-process')}
          >
            Hiring Process
          </button>
          <button
            className={activeTab === 'disability' ? 'active' : ''}
            onClick={() => handleTabClick('disability')}
          >
            Disability Accommodations
          </button>
          <button
            className={activeTab === 'faq' ? 'active' : ''}
            onClick={() => handleTabClick('faq')}
          >
            FAQ
          </button>
        </div>

        {/* Tab Content */}
        <div className="tab-content">
          {/* Hiring Process Tab */}
          {activeTab === 'hiring-process' && (
            <div className="faq-container">
              <div className="faq-heading-icon">
                <img src={faq_symbol} alt="FAQ Icon" />
                <h2>Hiring Process FAQs</h2>
              </div>
              <div className="faq-list">
                {renderFaqAccordion(hiringProcessFaqs)}
              </div>
            </div>
          )}

          {/* Disability Accommodations Tab */}
          {activeTab === 'disability' && (
            <div className="faq-container">
              <div className="faq-heading-icon">
                <img src={faq_symbol} alt="FAQ Icon" />
                <h2>Disability Accommodations</h2>
              </div>
              <div className="faq-list">
                <p>{disabilityAccommodationsContent}</p>
              </div>
            </div>
          )}

          {/* General FAQ Tab */}
          {activeTab === 'faq' && (
            <div className="faq-container">
              <div className="faq-heading-icon">
                <img src={faq_symbol} alt="FAQ Icon" />
                <h2>General FAQs</h2>
              </div>
              <div className="faq-list">
                {renderFaqAccordion(generalFaqs)}
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default Careers;

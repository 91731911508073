import React from "react";
import "./Billing.css";

// Import the images if using from local assets
import homeBanking from "../../assets/images/home_banking.svg";
import healthCondition from "../../assets/images/assist-health.svg";
import billForgivness from "../../assets/images/assist-bill-forginess.svg";
import monthlyDiscount from "../../assets/images/assist-monthly-discount.svg";
import OTBill_grant from "../../assets/images/assist-onetime-bill-grant.svg";
import federalBill from "../../assets/images/assist-federal-assist-bill.svg";
import disasterRelifImg2 from "../../assets/images/assist-disaste-reliefImg2.svg";
import paymentExtension from "../../assets/images/assist-payment-extension.svg";
import levelpayPlan from "../../assets/images/assist-lavelPay-plan.svg";
import AssistProgHeroIMg from '../../assets/images/assistance-program-hero-img.jpg'

export default function Assistance_Programs() {
  // Array of image paths
  const iconClasses = [
    homeBanking,
    healthCondition,
    billForgivness,
    monthlyDiscount,
    OTBill_grant,
    federalBill,
  ];

  // Array for card titles
  const cardTitles = [
    "Energy-efficient home upgrades",
    "Lower rates for health conditions",
    "Past due bill forgiveness",
    "CARE: 20% monthly discount",
    "One-time bill grant",
    "Federal bill assistance",
  ];

  // Array for card texts
  const cardTexts = [
    "Save money and energy with professional energy-saving home improvements at no cost to you.",
    "Get additional natural gas at the lowest rate if you or a household member requires additional space heating due to a medical condition.",
    "The Arrearage Management Plan offers qualifying customers the chance to have their eligible past due bills forgiven.",
    "You may qualify for 20% off your monthly gas bill. Our online application only takes a few minutes, and no additional documentation is needed.",
    "The Gas Assistance Fund (GAF) may be able to provide a one-time grant of up to $200.00 to cover your gas bill.",
    "You may qualify for an energy assistance grant through the Low Income Home Energy Assistance Program.",
  ];

  return (
    <>
      {/* Assist first container and hero section */}
      {/* <div className="assist-first-container container-fluid mt-2">
        <div className="row assist-hero-header">
          <div className="col d-flex align-items-center">
            <h1 className="assist-hero-heading">Assistance Programs</h1>
          </div>
        </div>
      </div> */}

<div className="assist-first-container container-fluid mt-2 first-container">
  <div className="service_hero_img position-relative">
    <img
      src={AssistProgHeroIMg}
      alt="Service Hero"
      className="img-fluid w-100"
      style={{ borderRadius: "5px" }}
    />
    <h1 className="assist-hero-heading">Assistance Programs</h1>
  </div>
</div>



      {/* Assist-second container and programs cards */}
      <div className="assist-second-container container mt-5 p-4">
  <div className="row">
    {iconClasses.map((iconClass, index) => (
      <div className="col-md-4 mb-3 mt-3" key={index}>
        <div
          className="assist-card card h-100 p-3 position-relative"
          style={{
            border: "none",
            background: "linear-gradient(135deg, #e0f7fa, #ffffff)",
            overflow: "hidden",
          }}
        >
          <div className="card-body d-flex flex-column">
            <div className="assist-icon-div d-flex justify-content-center">
              <img
                src={iconClass}
                alt="Icon"
                className="img-fluid"
                style={{
                  width: "50px",
                  height: "50px",
                  objectFit: "contain",
                }}
              />
            </div>
            <h5 className="card-title mt-3 assist-card-heading text-center">
              {cardTitles[index]}
            </h5>
            <p className="card-text text-center text-dark flex-grow-1">
              {cardTexts[index]}
            </p>
            <div className="Billing-btn d-flex justify-content-center mt-auto">
              <a href="#" className="btn assist-btn">
                Learn More
              </a>
            </div>
          </div>
        </div>
      </div>
    ))}
  </div>
</div>


      {/* asssit third container Emergency Disaster Realif */}

      <div className="container assist-third-container mt-md-5 pb-sm-4 pb-md-0" >
        <div className="row d-flex align-items-center h-100 ">
          <div className="col-md-9 text-center">
            <h1 className="assist-heading">Emergency Disaster Relief</h1>
            <p className="assist-paragraph mt-3">
              SoCalGas occasionally implements billing and credit policy changes
              to help ease the burden of customers impacted by certain events.
            </p>
            <div className="Billing-btn mt-4">
              <a href="#" className="btn">
                Learn More
              </a>
            </div>
          </div>
          <div className="col-md-3 d-flex justify-content-center">
            <div className="assist-bg-round d-flex align-items-center justify-content-center mt-4">
              <img
                className="assist-disaster-relief-img "
                src={disasterRelifImg2}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

{/* assist four container */}

      <div className="assist-container container mt-5">
        <h1 className="assist-heading mb-5">
          Need Extra Time to Pay a Bill?
        </h1>
        <div className="row d-flex justify-content-center mt-5">
          {/* First Card */}
          <div className="col-md-6 mb-4">
            <div className="card assist-custom-card h-100">
              <img
                src={paymentExtension}
                alt="Icon"
                className="mt-4 card-icon"
              />
              <div className="card-body mt-3">
                <h4 className="card-title">Payment Extension</h4>
                <p className="card-text mt-4">
                  If you need extra time to pay your natural gas bill, we’re
                  here to help. You can request an extension from My Account.
                </p>
                <div className=" d-flex justify-content-start mt-md-5 mt-sm-2 Billing-btn">
                  <a href="#" className="btn">
                    Extend Payment Due Date
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Second Card */}
          <div className="col-md-6  mb-4">
            <div className="card assist-custom-card h-100">
              <img src={levelpayPlan} alt="Icon" className="mt-4 card-icon" />
              <div className="card-body mt-3">
                <h4 className="card-title">Level Pay Plan</h4>
                <p className="card-text mt-4">
                  Smooth out the ups and downs of your monthly gas bill by
                  averaging annual gas consumption and costs over a 12-month
                  period.
                </p>
                <div className="Billing-btn d-flex justify-content-start mt-md-5 mt-sm-2">
                  <a href="#" className="btn">
                    Enroll
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* assist five container */}

      <div className="assist-five-container container">
  <h1 className="assist-heading">Our Other Assistance Programs</h1>
  <div className="row mt-5 assist-other-assist-prog">
    {/* Card 1 */}
    <div className="col-sm-6 col-lg-4 mb-4"> 
      <div
        className="card h-100 card-equal-height"
        style={{
          borderLeft: "none",
          borderRight: "none",
          borderTop: "5px solid #007bff", // Attractive color for the top border
          borderBottom: "5px solid #007bff", // Attractive color for the bottom border
          borderRadius: "10px",
                  }}
      >
        <div className="card-body d-flex flex-column">
          <h5 className="card-title">Low-cost Internet Home Options</h5>
          <p className="card-text mt-3">
            Some Internet Service Providers (ISPs) offer discounted plans
            for customers based on household income, participation in
            assistance programs, and more. To learn more, select below or
            call 1-877-245-8467.
          </p>
          <div className="mt-auto Billing-btn">
            <a href="#" className="btn">
              Learn More
            </a>
          </div>
        </div>
      </div>
    </div>

    {/* Card 2 */}
    <div className="col-sm-6 col-lg-4 mb-4">
      <div
        className="card h-100 card-equal-height"
        style={{
          borderLeft: "none",
          borderRight: "none",
          borderTop: "5px solid #007bff", // Attractive color for the top border
          borderBottom: "5px solid #007bff", // Attractive color for the bottom border
          borderRadius: "10px",
          
        }}
      >
        <div className="card-body d-flex flex-column">
          <h5 className="card-title">Statewide Referral Services</h5>
          <p className="card-text mt-3">
            Dial 211 to connect with agencies and community specialists
            who help you access the best local resources and services to
            address any need.
          </p>
          <div className="mt-auto Billing-btn">
            <a href="#" className="btn">
              Learn More
            </a>
          </div>
        </div>
      </div>
    </div>

    {/* Card 3 */}
    <div className="col-sm-6 col-lg-4 mb-4">
      <div
        className="card h-100 card-equal-height"
        style={{
          borderLeft: "none",
          borderRight: "none",
          borderTop: "5px solid #007bff", // Attractive color for the top border
          borderBottom: "5px solid #007bff", // Attractive color for the bottom border
          borderRadius: "10px",
          
        }}
      >
        <div className="card-body d-flex flex-column">
          <h5 className="card-title">
            Low Income Household Assistance Water Program
          </h5>
          <p className="card-text mt-3">
            The federal program provides financial assistance to manage
            residential water utility costs.
          </p>
          <div className="mt-auto Billing-btn">
            <a href="#" className="btn">
              Learn More
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

{/* assist six Accessibility Container */}

<div className="assist-six-container container mt-5">
  <h1 className="assist-heading">Accessibility</h1>
  <div className="row mt-5 assist-other-assist-prog">
    {/* Card 1 */}
    <div className="col-sm-6 col-lg-4 mb-4">
      <div
        className="card h-100 card-equal-height"
        style={{
          borderLeft: "none",
          borderRight: "none",
          borderTop: "5px solid #007bff", // Attractive color for the top border
          borderBottom: "5px solid #007bff", // Attractive color for the bottom border
          borderRadius: "10px",
        }}
      >
        <div className="card-body d-flex flex-column">
          <h5 className="card-title">
            Appliance Braille Marking for the Visually Impaired
          </h5>
          <p className="card-text mt-3">
            Technicians can mark with adhesive Braille dots the control
            knobs on natural gas ovens for the visually impaired.
          </p>
          <div className="mt-auto Billing-btn">
            <a href="#" className="btn">
              Learn More
            </a>
          </div>
        </div>
      </div>
    </div>

    {/* Card 2 */}
    <div className="col-sm-6 col-lg-4 mb-4">
      <div
        className="card h-100 card-equal-height"
        style={{
          borderLeft: "none",
          borderRight: "none",
          borderTop: "5px solid #007bff", // Attractive color for the top border
          borderBottom: "5px solid #007bff", // Attractive color for the bottom border
          borderRadius: "10px",
        }}
      >
        <div className="card-body d-flex flex-column">
          <h5 className="card-title">
            Deaf and Disabled Telecommunications Program (DDTP)
          </h5>
          <p className="card-text mt-3">
            California Connect is a public program that provides
            communications equipment and services to persons with
            disabilities.
          </p>
          <div className="mt-auto Billing-btn">
            <a href="#" className="btn">
              Learn More
            </a>
          </div>
        </div>
      </div>
    </div>

    {/* Card 3 */}
    <div className="col-sm-6 col-lg-4 mb-4">
      <div
        className="card h-100 card-equal-height"
        style={{
          borderLeft: "none",
          borderRight: "none",
          borderTop: "5px solid #007bff", // Attractive color for the top border
          borderBottom: "5px solid #007bff", // Attractive color for the bottom border
          borderRadius: "10px",
        }}
      >
        <div className="card-body d-flex flex-column">
          <h5 className="card-title">
            Customer Assistance Programs Brochure
          </h5>
          <p className="card-text mt-3">
            Explore all SoCalGas’ customer assistance programs. You can
            download it and print it at home.
          </p>
          <div className="mt-auto Billing-btn">
            <a href="#" className="btn">
              Download
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



      {/* assist seven container */}
      <div className="assist-container-seven container assist-container"> {/* Adjust padding here */}
        <div className="row mb-5">
          <div className="col">
            <h1 className="assist-heading mb-4">Community Outreach</h1>
            <p className="mt-3 mb-4">
              Are you associated with a community- or faith-based organization and interested in building awareness of our customer assistance programs in the communities you serve?
              <a href="#"> Get in touch.</a>
            </p>

            <h1 className="assist-heading mb-4 mt-4">
              Community Based Organizations Update
            </h1>
            <p className="mt-4 mb-4">
              The California Public Utilities Commission (CPUC) recently issued a Decision (D. 22-04-037) which provides a framework to establish and operate a Community Based Organization (CBO) Case Management Pilot Program (Pilot). This Pilot is designed to operate in targeted California communities where, during the first year of the COVID-19 pandemic, electric bills were highest relative to the available resources of the community.
            </p>
            <p className="mt-4 mb-5">
              To support the efforts of this Pilot, the Investor Owned Utilities (IOUs) have developed a statewide map that tracks arrearages and disconnections by Zip Codes, as well as identifies nearby CBOs who may be able to help customers lower or eliminate their arrearages.
            </p>
          </div>
        </div>
      </div>


    </>
  );
}

import React, { useState } from 'react';
import './css/app.css'; 
import treeMeasurementImg from '../assets/images/carobonfootprint.png'; 
import hydrogen from '../assets/images/hydrogen.png'; 
import pollution from '../assets/images/pollution.png'; 
import methane from '../assets/images/methane.png'; 

const initiatives = [
  {
    title: 'Methane Reduction',
    description: 'Exceed the state requirements to reduce methane emissions by 20% by 2025 and 40% by 2030.',
    detail: 'SoCalGas aims to exceed its targets ahead of schedule, using cutting-edge leak detection and repair technology.',
    icon: methane,
  },
  {
    title: 'Clean Fleet Initiative',
    description: 'Transition to a 100% zero-emission over-the-road fleet by 2035.',
    detail: 'Incorporating more alternative fuel vehicles and using renewable natural gas at all SoCalGas vehicle fueling stations.',
    icon: pollution,
  },
  {
    title: 'Hydrogen Blending Projects',
    description: 'Develop hydrogen infrastructure solutions and deliver 20% renewable natural gas to customers by 2030.',
    detail: 'Complete five hydrogen pilot projects by 2025 to support the 2028 Olympics and demonstrate hydrogen safety.',
    icon: hydrogen,
  },
];

const CarbonFootprint = () => {
  const [expanded, setExpanded] = useState(null);

  const handleExpand = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  return (
    <section className="carbon-footprint">
      {/* Section for title and background */}
      <div className="banner-title">
        <h1> Reducing carbon footprints and achieving carbon neutrality by 2045.    </h1>
      </div>

      {/* Full-width image section */}
      <div className="full-width-image">
        <img src={treeMeasurementImg} alt="Carbon Footprint Initiatives" />
      </div>

      {/* Carbon footprint introduction section */}
      <div className="container intro-section">
        <p className="carbon-description">
        <h1 className="section-title">What is a Carbon Footprint?</h1> 
        <hr />
          A carbon footprint is the total amount of greenhouse gases (including carbon dioxide and methane) generated by our actions.
          <br/>
          The average carbon footprint per person in the United States is 16 tons, one of the highest rates in the world. Globally, the average carbon footprint is closer to 4 tons. To avoid a 2°C rise in global temperatures, the global carbon footprint per year must drop to under 2 tons by 2050.
          <br/>
          Lowering individual carbon footprints requires small changes in our actions like eating less meat, flying less, and using more energy-efficient methods.
        </p>
      </div>

      {/* Initiatives section */}
      <div className="container">
      <h1 className='carbon-sub-heading'>Our Commitment to Carbon Footprint Reduction</h1>
        <p className="overview"> 
            <hr />
          At SoCalGas, we are committed to achieving net zero greenhouse gas emissions by 2045. Our efforts include reducing methane emissions, transitioning to clean fuels, and increasing energy efficiency for all customers.
        </p>

        <div className="initiatives-grid">
          {initiatives.map((item, index) => (
            <div className="initiative-card" key={index}>
              <img src={item.icon} alt={item.title} className="initiative-icon" />
              <h3 className="initiative-title">{item.title}</h3>
              <p className="initiative-description">{item.description}</p>
              {expanded === index && <p className="initiative-detail">{item.detail}</p>}
              <button className="expand-btn" onClick={() => handleExpand(index)}>
                {expanded === index ? 'Show Less' : 'Learn More'}
              </button>
            </div>
          ))}
        </div>

        <div className="cta">
          <button className="cta-btn">Learn More </button>
          {/* <button className="cta-btn">Join Us in Reducing Carbon Footprint</button> */}
        </div>
      </div>
    </section>
  );
};

export default CarbonFootprint;

import React from 'react';
import { FaSolarPanel, FaBullseye } from 'react-icons/fa';
import { MdOutlineAutoGraph } from 'react-icons/md';
import './css/app.css'; 
import energy from '../assets/images/energy.png'
import mission from '../assets/images/mission.png'
import advance_future from '../assets/images/adavnce-future.png'

const OurMission = () => {
  return (
    <section className="our-mission">
      {/* Main Heading */}
      <div className="mission-heading">
        <h4>Our Mission</h4>
        <h1>Reducing Emissions and <br/> Preserving Natural Resources</h1>
        <p>SoCalGas achieve their goal of Net Zero carbon emissions by 2045.</p> 
      </div>
    
      {/* 3-Column Section */}
      <div className="mission-sections">
        {/* The Evolution of Energy */}
        <div className="mission-item">
          {/* <FaSolarPanel size={60} color="#3a7ca5" /> */}
          <img src={energy} alt="energy"  />
          <h3>The Evolution of Energy</h3>
          <p>Exploring how renewable gas, power-to-gas, and hydrogen fuel cells are reshaping the future.</p>
          <button className="mission-button"> Learn more <span className="greater-than">&gt;</span></button>
        </div>

        {/* Aspire 2045 */}
        <div className="mission-item">
          {/* <FaBullseye size={60} color="#3a7ca5" />  */}
          <img src={mission} alt="energy" />
         <h3>Aspire 2045</h3>
          <p>Our goal is Net Zero by 2045. We're working on renewable energy, carbon capture, and more.</p>
          <button className="mission-button"> Learn more <span className="greater-than">&gt;</span></button>
        </div>

        {/* Advancing the Future */}
        <div className="mission-item">
          {/* <MdOutlineAutoGraph size={60} color="#3a7ca5" /> */}
          <img src={advance_future} alt="energy" />
          <h3>Advancing the Future</h3>
          <p>Leading innovations in energy and technology to make a safe, cleaner, and brighter future.</p>
          <button className="mission-button"> Learn more <span className="greater-than">&gt;</span></button>
        </div>
      </div>
    </section>
  );
};

export default OurMission;

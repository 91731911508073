import React from "react";
import "./css/app.css";
import news_img from '../assets/images/hydrogen.png';

const newsItems = [
  {
    id: 1,
    date: "23 MAY 2022",
    title: 'Protect Against Scams and Imposters',
    image: news_img,
  },
  {
    id: 2,
    date: "24 MAY 2022",
    title: 'Harvesting Fresh Produce for LA Families',
    image: news_img,
  },
  {
    id: 3,
    date: "23 APRIL 2022",
    title: 'SoCalGas and EVOLOH Research Project',
    image: news_img,
  },
];

const NewsSection = () => {
  return (
    <div className="news-section">
      <div className="container"> 
        <h2 className="section-title">The Latest News</h2>
        <button className="view-all-btn">View All News</button>
        <div className="news-grid">
          {newsItems.map((news) => (
            <div key={news.id} className="news-cards">
              <div className="image-container">
                <img src={news.image} alt={news.title} className="news-image" />
                <div className="date-circle">
                  <span>{news.date.split(' ')[0]}</span>
                  <small>{news.date.split(' ')[1]}</small>
                </div>
              </div>
              <div className="article-label">Article</div> {/* Moved here, above the title */}
              <h3 className="news-title">{news.title}</h3>
              <a href="#" className="read-more">
                Read More <span className="read-more-arrow">→</span>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NewsSection;

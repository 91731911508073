import React from "react";
import './css/app.css'; // Your custom CSS file
import banner1 from '../assets/images/banner1.png'; // Import your images

const ProductBanner = () => {
    return (
        <div className="product-banner-container">

            <h1 className="headline">Energy-Saving Products & Promotions:</h1> {/* Changed to <h1> for SEO */}

            {/* Bootstrap Carousel */}
            <div id="carouselExampleAutoplaying" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img src={banner1} className="d-block w-100 slider-image" alt="Energy-saving products slide 1" />
                    </div>
                    <div className="carousel-item">
                        <img src={banner1} className="d-block w-100 slider-image" alt="Energy-saving products slide 2" />
                    </div>
                    <div className="carousel-item">
                        <img src={banner1} className="d-block w-100 slider-image" alt="Energy-saving products slide 3" />
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>

            {/* Static Content Boxes */}
            <section className="content-boxes">
                <article className="content-box">
                    <h2 className="section-titles dot-bullet">Rebates & Incentives</h2>
                    <p>Search for available rebates to potentially help you save money. </p>
                    <button className="learn-more-btns">Learn More</button>
                </article>

                <article className="content-box">
                    <h2 className="section-titles dot-bullet">Energy-Saving Tips & Tools</h2>
                    <p>Helpful tips and tools on how you may be able to conserve energy and lower your natural gas bill. </p>
                    <button className="learn-more-btns">Learn More</button>
                </article>

                <article className="content-box">
                    <h2 className="section-titles dot-bullet">Assistance Programs</h2>
                    <p> Learn how our assistance programs could help you save money and make your home more energy efficient.</p>
                    <button className="learn-more-btns">Learn More</button>
                </article>
            </section>
        </div>
    );
};

export default ProductBanner;

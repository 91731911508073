import React from "react";
import { Link, useLocation } from "react-router-dom";
import "../../pages/css/app.css";
import SoCalGasLogo from "../../assets/images/scg-logo-white.svg";
import SoCalGasLogoBlue from "../../assets/images/scg-logo-white.svg";

const Header = () => {
  const location = useLocation();
  const isHomePage = location.pathname === "/";
  const navbarStyle = {
    backgroundColor: isHomePage ? "transparent" : "#fff",
    color: isHomePage ? "#fff" : "#000",
  };

  const linkStyle = {
    color: isHomePage ? "#fff" : "#000",
  };

  return (
    <header>
      <nav className="navbar" style={navbarStyle}>
        <div className="navbar-logo">
          <Link to="/">
            {isHomePage ? (
              <img
                src={SoCalGasLogo}
                alt="SoCalGas Logo White"
                className="logo"
                height={35}
              />
            ) : (
              <img
                src={SoCalGasLogoBlue}
                alt="SoCalGas Logo Blue"
                className="logo"
                height={35}
              />
            )}
          </Link>
        </div>
        <ul className="navbar-menu">
          <li className="navbar-item">
            <Link to="/" style={linkStyle}>
              Home Page 
            </Link>
          </li>
          <li className="navbar-item">
            <Link to="/" style={linkStyle}>
              My Account
            </Link>
            <ul className="dropdown">
              <li>
                <Link to="/account-overview">Account Overview</Link>
              </li>
              <li>
                <Link to="/billing-payments">Billing and Payments</Link>
              </li>
              <li>
                <Link to="/service-management">Service Management</Link>
              </li>
              <li>
                <Link to="/alerts-notifications">Alerts and Notifications</Link>
              </li>
            </ul>
          </li>
          <li className="navbar-item">
            <Link to="/energy-usage" style={linkStyle}>
              Energy Usage
            </Link>
            <ul className="dropdown">
              <li>
                <Link to="/usage-overview">Usage Overview</Link>
              </li>
              <li>
                <Link to="/energy-insights">Energy Insights</Link>
              </li>
              <li>
                <Link to="/smart-devices">Smart Devices</Link>
              </li>
              <li>
                <Link to="/energy-reports">Energy Reports</Link>
              </li>
            </ul>
          </li>
          <li className="navbar-item">
            <Link to="/marketplace" style={linkStyle}>
              Marketplace
            </Link>
            <ul className="dropdown">
              <li>
                <Link to="/shop-products">Shop Energy Efficient Products</Link>
              </li>
              <li>
                <Link to="/promotions-offers">Promotions and Offers</Link>
              </li>
              <li>
                <Link to="/product-reviews">Product Reviews and Ratings</Link>
              </li>
              <li>
                <Link to="/financing-options">Financing Options</Link>
              </li>
            </ul>
          </li>
          <li className="navbar-item">
            <Link to="/support" style={linkStyle}>
              Support & Contact
            </Link>
            <ul className="dropdown">
              <li>
                <Link to="/contact-us">Contact Us</Link>
              </li>
              <li>
                <Link to="/live-chat">Live Chat</Link>
              </li>
              <li>
                <Link to="/schedule-call">Schedule a Call</Link>
              </li>
              <li>
                <Link to="/faqs">FAQs</Link>
              </li>
              <li>
                <Link to="/help-center">Help Center</Link>
              </li>
              <li>
                <Link to="/community-forum">Community Forum</Link>
              </li>
            </ul>
          </li>
          <li className="navbar-item">
            <Link to="/community" style={linkStyle}>
              Community
            </Link>
            <ul className="dropdown">
              <li>
                <Link to="/events-webinars">Events and Webinars</Link>
              </li>
              <li>
                <Link to="/community-initiatives">Community Initiatives</Link>
              </li>
              <li>
                <Link to="/energy-savings-programs">Energy Savings Programs</Link>
              </li>
              <li>
                <Link to="/feedback-suggestions">Feedback and Suggestions</Link>
              </li>
            </ul>
          </li>
          <li className="navbar-item">
            <Link to="/about-us" style={linkStyle}>
              About Us
            </Link>
            <ul className="dropdown">
              <li>
                <Link to="/company-overview">Company Overview</Link>
              </li>
              <li>
                <Link to="/leadership-team">Leadership Team</Link>
              </li>
              <li>
                <Link to="/sustainability-initiatives">Sustainability Initiatives</Link>
              </li>
              <li>
                <Link to="/careers">Careers</Link>
              </li>
            </ul>
          </li>
          <li className="navbar-item">
            <Link to="/legal" style={linkStyle}>
              Legal & Compliance
            </Link>
            <ul className="dropdown">
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/terms-service">Terms of Service</Link>
              </li>
              <li>
                <Link to="/regulatory-information">Regulatory Information</Link>
              </li>
              <li>
                <Link to="/security-safety">Security and Safety</Link>
              </li>
            </ul>
          </li>
          <li className="navbar-item">
            <Link to="/login-register" className="navbar-login" style={linkStyle}>
              Login / Register
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;

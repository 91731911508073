import React, { useState, useEffect } from 'react';
import './css/app.css';
import Header from '../components/common/Header';
import slide1 from '../assets/images/new-hero.jpg';
import slide2 from '../assets/images/hydrogen.png';
import slide3 from '../assets/images/cleanfuel.png';

const AboutUs = () => {
    const images = [slide1, slide2, slide3];
    const [currentImage, setCurrentImage] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImage((prevImage) => (prevImage + 1) % images.length);
        }, 5000);
        return () => clearInterval(interval);
    }, [images.length]);

    return (
        <div className="about-us-container">

            <section className="about-us-banner">
                <div className="about-us-overlay">
                    {/* <h1>About Us</h1>
                    <p>Powering Tomorrow with Renewable Solar Energy</p> */}
                </div>
            </section>

            <section className="about-us-tag">
                <h2>About Us</h2>
                <hr />
            </section>

            <section className="about-us-content">
                <div className="about-us-carousel-left">
                    <div className="about-us-image-container">
                        {images.map((image, index) => (
                            <img
                                key={index}
                                src={image}
                                alt={`Slide ${index + 1}`}
                                className={`about-us-carousel-image ${index === currentImage ? 'active' : 'inactive'}`}
                                style={{
                                    opacity: index === currentImage ? 1 : 0,
                                    transition: 'opacity 1s ease-in-out',
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                }}
                            />
                        ))}
                    </div>
                </div>

                <div className="about-us-content-right">
                    <h2>WHO WE ARE</h2>
                    <p>
                        As the nation's largest natural gas distribution utility, we deliver increasingly clean, safe and reliable energy to 21.1 million consumers through 5.9 million meters in more than 500 communities.
                        Our service territory encompasses approximately 24,000 square miles in diverse terrain throughout Central and Southern California, from Visalia to the Mexican border.
                        SoCalGas is a regulated subsidiary of Sempra.  (NYSE: SRE), a Fortune 500 energy services holding company based in San Diego. For additional information, please visit the Investor Relations section.
                        For more than 150 years, SoCalGas has served Central and Southern California as a responsible and engaged environmental leader, employer and neighbor.
                        With safe, clean, affordable, reliable and abundant domestic sources of natural gas, SoCalGas' innovation is fueling new possibilities in California.
                        Southern California is our home, too. We strive to improve the quality of life in our communities by maintaining a diverse workforce, working with suppliers that represent and reflect the communities we serve and giving back through our charitable contributions and employee volunteer activities. Like other investor-owned utilities in the state, SoCalGas' operations are regulated by the California Public Utilities Commission and other state and federal agencies.
                    </p>
                </div>
            </section>

            <div className="about-us-info-sections container">
                <div className="about-us-mission">
                    <h3 className='dot-bullet'>Mission</h3>
                    <p>
                        SoCalGas's mission to build the cleanest, safest, and most innovative energy infrastructure company in America. They are committed to achieving Net Zero carbon emissions by 2045. The video highlights four innovative technologies that are helping them on their journey to Net Zero: renewable gas, power-to-gas, hydrogen fuel cells, and carbon capture utilization and sequestration.
                        Renewable gas: harnesses waste streams and reuses them to make power.
                        Power-to-gas: uses excess renewable electricity to create renewable gas and hydrogen, which can be stored, transported, and utilized for decades.
                        Hydrogen fuel cells: allow people to reliably power their homes, businesses, and transportation with net zero emissions.
                        Carbon capture utilization and sequestration: captures and utilizes carbon in new production processes for products of the future.

                    </p>
                </div>
                <div className="about-us-vision">
                    <h3 className='dot-bullet'>Vision</h3>
                    <p>
                        SoCalGas has a history of reducing emissions. They have already reduced distribution emission rates to 0.25% while continuing to serve their 22 million customers without sacrificing reliability, resiliency, or affordability. In 2010, renewable gas wasn't even in use, but by 2022, they will reach 5% capacity throughout their infrastructure. By 2030, they aim to reach 20% renewable gas capacity.
                    </p>
                </div>

                <div className="about-us-vision ">
                    <h3 className='dot-bullet'>Values</h3>
                    <p>
                        <b>  At SoCalGas we live by three values: Do the Right Thing, Champion People and Shape the Future. </b> <br />
                        These values permeate our company. They guide how we think and drive our actions. As one of America’s largest energy providers, our values are the lens through which we see every opportunity and challenge.
                        With our values providing a strong foundation, our mission statement presents an actionable framework of purpose and intent. At SoCalGas, we’re dedicated to leading the transition to a decarbonized energy system.
                        We believe every Californian deserves a clean, affordable, resilient energy future. SoCalGas is working to achieve that future through innovation, collaboration, and decarbonization.
                    </p>
                </div>

            </div>
        </div>
    );
};

export default AboutUs;

import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Homepage from './pages/Homepage.jsx';
import Ways_to_pay from './pages/billingpage/Ways_to_pay.jsx';
import AboutUs from './pages/Aboutus.jsx';
import OurTeam from './pages/OurTeam.jsx';
import Assistance_Programs from './pages/billingpage/Assistance_Programs.jsx';
import Service from './pages/Socal_Service.jsx/Service.jsx';
import ContactUs from './pages/ContactUs.jsx';
import Header from './components/common/Header.jsx';
import Footer from './components/Footer.jsx';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
import Sustainability from './pages/Sustainability.jsx';
import Manage_higherBill from './pages/billingpage/Manage_higherBill.jsx';
import Careers from './pages/Careers.jsx';
import Understand_myBill from './pages/billingpage/Understand_myBill.jsx';
import BusinessBilling from './pages/billingpage/BusinessBilling.jsx';

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto'  
    });
  }, [pathname]);

  return (
    <>
      {pathname !== "/" && <Header />}

      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/service-management" element={<Service />} />
        <Route path="/ways-To-Pay" element={<Ways_to_pay/>} />
        <Route path="/company-overview" element={<AboutUs />} />
        <Route path="/leadership-team" element={<OurTeam />} />
        <Route path="/sustainability-initiatives" element={<Sustainability />} />
        <Route path="/careers" element={<Careers/>} /> 
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/assistance-Program" element={<Assistance_Programs />} />
        <Route path="/managehigh-Bill" element={<Manage_higherBill/>} />
        <Route path="/understand-MyBill" element={<Understand_myBill/>} />
        <Route path="/business-Billing" element={<BusinessBilling/>} />
               
      </Routes>

      <Footer />
    </>
  );
}

export default App;

import React from 'react';
import '../pages/css/app.css';
import SoCalGasLogo from '../assets/images/scg-logo-white.svg';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-logo-section">
                    <img src={SoCalGasLogo} alt="SoCalGas Logo" className="footer-logo" />
                    <p className="footer-description">
                        Our mission is to build the cleanest, safest, and most innovative energy infrastructure company in America.
                    </p>

                    {/* Social Media Icons */}
                    <div className="footer-social">
                        <a href="#"><i className="fab fa-linkedin"></i></a>
                        <a href="#"><i className="fab fa-facebook"></i></a>
                        <a href="#"><i className="fab fa-instagram"></i></a>
                        <a href="#"><i className="fab fa-x-twitter"></i></a>
                        <a href="#"><i className="fab fa-youtube"></i></a>
                    </div>
                </div>


                {/* Footer Sections */}
                <div className="footer-sections">
                    {/* Services Section */}
                    <div className="footer-section">
                        <h3 className="footer-title">Services</h3>
                        <ul>
                            <li><a href="#">Residential Services</a></li>
                            <li><a href="#">Business Services</a></li>
                            <li><a href="#">Start Service</a></li>
                            <li><a href="#">Stop Service</a></li>
                            <li><a href="#">Transfer Service</a></li>
                        </ul>
                    </div>

                    {/* Billing Section */}
                    <div className="footer-section">
                        <h3 className="footer-title">Billing</h3>
                        <ul>
                           <Link to={"/ways-To-Pay"}> <li><a href="#">Ways to Pay</a></li></Link>
                           <Link to={"/assistance-Program"}> <li><a href="#">Assistance Programs</a></li></Link>
                           <Link to={"/managehigh-Bill"}><li><a href="#">Manage High Bills</a></li></Link>
                           <Link to={"/understand-MyBill"}> <li><a href="#">Understanding My Bill</a></li></Link>                            
                            <Link to={"/business-Billing"}><li><a href="#">Business Billing</a></li></Link>
                        </ul>
                    </div>

                    {/* Savings Section */}
                    <div className="footer-section">
                        <h3 className="footer-title">Savings</h3>
                        <ul>
                            <li><a href="#">Rebates and Incentives</a></li>
                            <li><a href="#">Energy Saving Tips & Tools</a></li>
                            <li><a href="#">Business Savings</a></li>
                            <li><a href="#">Business Rebates and Incentives</a></li>
                        </ul>
                    </div>

                    {/* Safety Section */}
                    <div className="footer-section">
                        <h3 className="footer-title">Safety</h3>
                        <ul>
                            <li><a href="#">Emergency Resources</a></li>
                            <li><a href="#">Safety & Prevention</a></li>
                            <li><a href="#">Safety Resources</a></li>
                            <li><a href="#">Methane Emissions</a></li>
                        </ul>
                    </div>

                    {/* About Us Section */}
                    <div className="footer-section">
                        <h3 className="footer-title">About Us</h3>
                        <ul>
                            <li><a href="#">Our Mission</a></li>
                            <li><a href="#">Careers</a></li>
                            <li><a href="#">Newsroom</a></li>
                            <li><a href="#">Contact Us</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            {/* Footer Bottom */}
            <div className="footer-bottom">
                <p>&copy; 2024 Southern California Gas Company. All rights reserved.</p>
            </div>
        </footer>
    );
}

export default Footer;

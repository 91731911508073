import React from "react";
import service_heroimg from "../../assets/images/service_heroimg.jpg";
import Start_serviceimg from "../../assets/images/start_serviceimg.svg";
import Transport_service from "../../assets/images/Start_Transportingimg.svg";
import Stop_serviceimg from "../../assets/images/Stop_serviceimg.svg";
import Restore_serviceimg from "../../assets/images/service_restore_img.jpg";
import Servie_builderimg from "../../assets/images/service-builderimg.jpg";
import service_appointment from '../../assets/images/Schedule Service Appointmenticon.svg'
import request from '../../assets/images/request icon.svg'
import Relight from '../../assets/images/pilot relight.svg'
import { useState } from "react";
import './services.css'


const Service = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index); // Toggle visibility
  };
  const faqData = [
    {
      question: "How does starting a service differ from transferring it?",
      answer:
        "Transferring service is intended for customers who currently have gas service and want to move it to a different address within our service area. Starting service is for both new and existing customers who need to initiate gas service at a new or existing address. If you wish to change or update the name on an existing account, you can do so by accessing the Manage Account Information section.",
    },
    {
      question: "What are the requirements to start a service?",
      answer: "Your new address must fall within our service area. A gas meter needs to be installed at the new location. If there isn't a meter, please submit a Request for Residential Gas Service to arrange for installation. Have your social security number ready, as it will be used to validate your identity through Experian, a national credit reporting agency. SoCalGas® employs secure technology to protect your personal information. If you cannot provide your social security number, please call 1-877-238-0092 for assistance. A one-time service fee of $25 will be included in your first bill.",
    },
    {
      question: "What information is required to transfer a service?",
      answer: "Your new address must be in our service area. A meter must be installed. If there is no meter, please submit a Request for Residential Gas Service to have a meter installed. SoCalGas® uses proven, trusted technology to protect your personal information. A one-time service fee of $25 will be added to your first bill. A technician may need to enter your residence to turn on gas at the new address.",
    },
    {
      question: "How can I restore service if it has been disconnected?",
      answer: "To restore service call us at 1-877-238-0092.",
    },
  ];
  return (
    <>
      {/* Hero Image Section */}
      <div className="container-fluid mt-2">
        <div className="service_hero_img">
          <img
            src={service_heroimg}
            alt="Service Hero"
            className="img-fluid w-100"
            style={{ borderRadius: "5px" }}
          />
        </div>
      </div>

      {/* Service Cards */}
      <div className="container mt-5">
        <h1 className="service_heading_STS mt-2 mb-5 ">
          Need to start, transfer, or stop service?
        </h1>
        <div className="row g-4 mt-5 justify-content-center mb-5">
          {/* Start Service Card */}
          <div className="col-sm-12 col-md-6 col-lg-4 mt-5">
            <div className="service-card p-4 text-center">
              <img
                src={Start_serviceimg}
                alt="Start Service"
                className="img-fluid service_icons"
              />
              <h5 className="mt-3 service_subheading">Start Service</h5>
              <p className="service-subheading-text">
                You're a new customer and want to start service right away.
              </p>
              <a href="#" className="btn btn-outline-primary service-btn">
                Start Service
              </a>
            </div>
          </div>

          {/* Transfer Service Card */}
          <div className="col-sm-12 col-md-6 col-lg-4 mt-5">
            <div className="service-card p-4 text-center">
              <img
                src={Transport_service}
                alt="Transfer Service"
                className="img-fluid service_icons"
              />
              <h5 className="mt-3 service_subheading">Moving?</h5>
              <p className="service-subheading-text">
                You're an existing customer and want to transfer service to a
                new address.
              </p>
              <a href="#" className="btn btn-outline-primary service-btn">
                Transfer Service
              </a>
            </div>
          </div>

          {/* Stop Service Card */}
          <div className="col-sm-12 col-md-6 col-lg-4 mt-5">
            <div className="service-card p-4 text-center">
              <img
                src={Stop_serviceimg}
                alt="Stop Service"
                className="img-fluid service_icons"
              />
              <h5 className="mt-3 service_subheading">Stop Service</h5>
              <p className="service-subheading-text">
                You're an existing customer and want to stop service at your
                current address.
              </p>
              <a href="#" className="btn btn-outline-primary service-btn">
                Stop Service
              </a>
            </div>
          </div>
        </div>

        {/* Service Appointment Section */}
        <div className="row g-3 mt-5 justify-content-center">
  <h1 className="service_appoinment-heading text-center">
    Service Appointments
  </h1>

  {/* Schedule Service Appointment */}
  <div className="col-sm-12 col-md-6 col-lg-4">
    <div className="service-card-appoinment border p-4 text-center">
    <img src={service_appointment} alt="" style={{height:"40px"}}/>
      <h5 className="service-title">Schedule a service appointment</h5>
      <p className="service-appoinment-text">
        Get a SoCalGas technician to service an appliance, tune-up equipment
      </p>
      <a href="#" className="service-appoinemt-link">
        Schedule <span>&#8250;</span>
      </a>
    </div>
  </div>

  {/* Look Up Service Request */}
  <div className="col-sm-12 col-md-6 col-lg-4">
    <div className="service-card-appoinment border p-4 text-center">
    <img src={request} alt="" style={{height:"40px"}}/>
      <h5 className="service-title">
        Look up your start service request
      </h5>
      <p className="service-appoinment-text">
        Check on the details and status of your start service appointment.
      </p>
      <a href="#" className="service-appoinemt-link">
        Manage Appointments <span>&#8250;</span>
      </a>
    </div>
  </div>

  {/* Pilot Relight */}
  <div className="col-sm-12 col-md-6 col-lg-4">
    <div className="service-card-appoinment border p-4 text-center">
    <img src={Relight} alt="" style={{height:"40px"}}/>
      <h5 className="service-title">Pilot Relight</h5>
      <p className="service-appoinment-text">
        Schedule your relight service before the weather gets cold.
      </p>
      <a href="#" className="service-appoinemt-link">
        Schedule Appointment <span>&#8250;</span>
      </a>
    </div>
  </div>
</div>


        {/* Restore Service Section */}
        <div className="container service-restore mt-5">
          <div className="row align-items-center">
            <div className="col-md-6">
              <img
                src={Restore_serviceimg}
                alt="Restore Service"
                className="img-fluid service-restore-img"
              />
            </div>
            <div className="col-md-6">
              <h2 className="restore-service-heading text-center mt-5">
                Restore Service
              </h2>
              <p className="text-muted text-center restore-service-info">
                If the meter is closed for non-payment of bills, contact us to
                inquire about the total charges due. Charges to restore service
                usually include the amount of any past due bills,
                deposit/additional deposit and the reconnection fee.
              </p>
              <div className="d-flex justify-content-center">
                <button className="btn btn-outline-primary service-restore-btn">
                  Contact Us
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Builder Services Section */}
        <div className="container service-restore mt-5">
          <div className="row align-items-center">
            <div className="col-md-6">
              <h2 className="restore-service-heading text-center mt-5">
                Builder Services
              </h2>
              <p className="text-center restore-service-info">
                Submit an application for new construction projects to install a
                natural gas pipeline and/or meter, request to modify an existing
                one, or pay your construction project invoice.
              </p>
              <p className="text-muted text-center restore-service-info">
                Visit this page to learn more about Building Services, to access
                the application for Natural Gas Service for Residential,
                Commercial, and Industrial New Construction, and to request
                modification of SoCalGas-owned meters and pipelines.
              </p>
              <div className="d-flex justify-content-center">
                <button className="btn btn-outline-primary service-restore-btn">
                  Contact Us
                </button>
              </div>
            </div>
            <div className="col-md-6">
              <img
                src={Servie_builderimg}
                alt="Builder Services"
                className="img-fluid service-builder-img"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <div className="">{/* <img src={} alt="" /> */}</div>
          </div>
          <div className="col-9"></div>
        </div>

        {/* Frequently Asked Qustions */}
        <div className="service-faq-container">
          <div className="service-faq-header">
            <h2 className="">Frequently asked questions</h2>
          </div>
          {faqData.map((faq, index) => (
            <div
              className={`service-faq-item ${
                activeIndex === index ? "active" : ""
              }`}
              key={index}
            >
              <button
                className="service-faq-question"
                onClick={() => handleClick(index)}
              >
                {faq.question}
                <span className="service-faq-icon">
                  {activeIndex === index ? "-" : "+"}
                </span>
              </button>
              <div
                className={`service-faq-answer ${
                  activeIndex === index ? "show" : ""
                }`}
              >
                {faq.answer}
              </div>
            </div>
          ))}
        </div>
    
      </div>

    
    </>
  );
};

export default Service;

import React, { useState } from 'react';
import './css/app.css';
import Aspire from '../assets/images/aspire.png';
import { FaBolt, FaGlobe, FaHeart, FaUsers, FaShieldAlt, FaHandHoldingUsd } from 'react-icons/fa';
import fuel_img from '../assets/images/renewablegas.png'
import hydrogen_img from '../assets/images/powergeneration.png'
import angeles_logo from '../assets/images/angeles-logo.png'
import CarbonFootprint from './CarbonFootprint';

const Sustainability = () => {

    const [activeTab, setActiveTab] = useState("Energy Solutions");

    const renderContent = () => {
        switch (activeTab) {
            case "Energy Solutions":
                return (
                    <div className="content-section">
                        <div className="content-block">
                            <img
                                src={fuel_img}
                                alt="Clean Fuels"
                                className="content-image"
                            />
                            <div className="content-text">
                                <h2>Renewable Natural Gas</h2>
                                <p>
                                    Converting waste products into renewable gas may help California meet its energy needs with local resources.
                                </p>
                                <button className="learn-more-btn">Learn More</button>
                            </div>
                        </div>
                        <div className="content-block">
                            <img
                                src={hydrogen_img}
                                alt="Hydrogen"
                                className="content-image"
                            />
                            <div className="content-text">
                                <h2>Power Generation Technologies</h2>
                                <p>
                                    Localizing power generation, such as a fuel cell, can increase energy resiliency and power quality.
                                </p>
                                <button className="learn-more-btn">Learn More</button>
                            </div>
                        </div>
                    </div>
                );

            case "Distribution":
                return (
                    <div className="content-section">
                        <div className="content-block">
                            <img
                                src={angeles_logo}
                                alt="Clean Fuels"
                                className="content-image"
                            />
                            <div className="content-text">
                                <h2>Angeles Link</h2>
                                <p>
                                    Learn about our plan to develop the nation's largest clean, renewable hydrogen energy pipeline system.                                </p>
                                <button className="learn-more-btn">Learn More</button>
                            </div>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };


    return (
        <>
            <section className="sustainability-section">
                {/* Hero Section */}
                <div className="hero-banner">
                    <h1 className="hero-title"> Sustainability </h1>
                </div>

                {/* First Content Section */}
                <div className="content-wrapper">
                    <div className="left-content">
                        <h2 className="heading">Aspire 2045</h2>
                        <hr />
                        <p className="sub-heading">Our strategy to further integrate sustainability across our business</p>
                        <p className="description">
                            Building on our March 2021 Climate Commitment, we aim to achieve net zero greenhouse gas emissions in our
                            operations and delivery of energy by 2045. We focus on five areas where we can have a strong, positive impact
                            on our communities, company, and stakeholders.
                        </p>
                        <button className="learn-more-button">Learn More</button>
                    </div>
                    <div className="right-content">
                        <img src={Aspire} alt="Organization Carbon Footprint" className="content-image" />
                    </div>
                </div>
            </section>

            <section className="commitment-section">
                <h2 className="commitment-title">Our commitment to a better tomorrow</h2>

                <div className="commitment-items">
                    {/* Each commitment item */}
                    <div className="commitment-item">
                        <FaBolt className="commitment-icon" />
                        <h3 className="commitment-heading">Energy Transition</h3>
                        <p className="commitment-description">Advancing the way to clean energy.</p>
                    </div>

                    <div className="commitment-item">
                        <FaGlobe className="commitment-icon" />
                        <h3 className="commitment-heading">Environment</h3>
                        <p className="commitment-description">Protecting the climate and improving air quality.</p>
                    </div>

                    <div className="commitment-item">
                        <FaHandHoldingUsd className="commitment-icon" />
                        <h3 className="commitment-heading">Affordability</h3>
                        <p className="commitment-description">Increasing cleaner energy access and affordability.</p>
                    </div>

                    <div className="commitment-item">
                        <FaUsers className="commitment-icon" />
                        <h3 className="commitment-heading">Diversity</h3>
                        <p className="commitment-description">Advancing diversity, equity, and inclusion in the communities we serve.</p>
                    </div>

                    <div className="commitment-item">
                        <FaShieldAlt className="commitment-icon" />
                        <h3 className="commitment-heading">Safety</h3>
                        <p className="commitment-description">Achieving world-class safety for our employees.</p>
                    </div>
                </div>
            </section>


            <div className="tabs-container">
                <nav className="tab-header">
                    <button
                        className={activeTab === "Energy Solutions" ? "active" : ""}
                        onClick={() => setActiveTab("Energy Solutions")}
                    >
                        Energy Solutions
                    </button>

                    <button
                        className={activeTab === "Distribution" ? "active" : ""}
                        onClick={() => setActiveTab("Distribution")}
                    >
                        Distribution
                    </button>
                </nav>
                <div className="tab-content-container">{renderContent()}</div>
            </div>
 
            <CarbonFootprint/> 

        </>

    );
};

export default Sustainability;

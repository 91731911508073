import React from 'react';
import ContactMap from './ContactMap';

const ContactUs = () => {
  return (
    <div className="container-fluid mt-2 border rounded-lg shadow-lg contact-page">
      <section className="contact-us d-flex">
        {/* Contact Information Section */}
        <div className="contact-info flex-grow-1 p-4 overflow-auto">
          <h2 className="text-center font-weight-bold mb-5 contactus-heading">Contact Us</h2>
          
          {/* Emergency Section */}
          <div className="contact-section contact-form-emergency bg-light p-3 mb-4 rounded">
            <h3 className="text-primary">For Gas Emergencies or Safety Issues</h3>
            <p><strong>Available 24/7</strong></p>
            <ul className="list-unstyled">
              <li><i className="fas fa-exclamation-triangle"></i> Emergencies: <a href="tel:911" className="text-info">911</a></li>
              <li><i className="fas fa-gas-pump"></i> Gas Odor or Carbon Monoxide: <a href="tel:1-877-238-0092" className="text-info">1-877-238-0092</a></li>
              <li><i className="fas fa-deaf"></i> Hearing Impaired (TDD/TTY): <a href="tel:1-800-252-0259" className="text-info">1-800-252-0259</a></li>
              <li><i className="fas fa-shovel"></i> Call Before You Dig (811 DigAlert): <a href="tel:811" className="text-info">811</a> (Free service)</li>
            </ul>
          </div>
          
          {/* General Customer Service Section */}
          <div className="contact-section contact-form-general-section bg-white p-3 mb-4 rounded shadow-sm">
            <h3 className="text-primary">General Customer Service</h3>
            <p><strong>Mon – Fri: 7:00 a.m. – 8:00 p.m.</strong><br />
              <strong>Sat: 7:00 a.m. – 6:00 p.m.</strong></p>
            <ul className="list-unstyled">
              <li><i className="fas fa-phone"></i> General Inquiries: <a href="tel:1-877-238-0092" className="text-info">1-877-238-0092</a></li>
              <li><i className="fas fa-globe"></i> International Callers: <a href="tel:001-909-307-7070" className="text-info">001-909-307-7070</a></li>
              <li><i className="fas fa-building"></i> Commercial Customers: <a href="tel:1-800-427-2000" className="text-info">1-800-427-2000</a> (Mon – Fri: 7:00 a.m. – 6:00 p.m.)</li>
              <li><i className="fas fa-utensils"></i> Food Service Equipment: <a href="tel:1-562-803-7323" className="text-info">1-562-803-7323</a> (Mon – Fri: 7:30 a.m. – 4:30 p.m.)</li>
            </ul>
          </div>

          <div className="contact-section contact-form-multilingual bg-light p-3 mb-4 rounded">
      <h3 className="text-primary">Multilingual Customer Service</h3>
      <ul className="list-unstyled">
        <li><i className="fas fa-language"></i> Español: <a href="tel:1-800-342-4545" className="text-info">1-800-342-4545</a></li>
        <li><i className="fas fa-language"></i> 國語: <a href="tel:1-800-427-1429" className="text-info">1-800-427-1429</a></li>
        <li><i className="fas fa-language"></i> 粵語: <a href="tel:1-800-427-1420" className="text-info">1-800-427-1420</a></li>
        <li><i className="fas fa-language"></i> 한국어: <a href="tel:1-800-427-0471" className="text-info">1-800-427-0471</a></li>
        <li><i className="fas fa-language"></i> Tiếng Việt: <a href="tel:1-800-427-0478" className="text-info">1-800-427-0478</a></li>
        <li><i className="fas fa-language"></i> Other Languages: <a href="tel:1-888-427-1345" className="text-info">1-888-427-1345</a></li>
      </ul>
    </div>
    <div className="contact-section contact-form-media-section bg-white p-3 mb-4 rounded shadow-sm">
      <h3 className="text-primary">24-Hour Media Line</h3>
      <p><i className="fas fa-newspaper"></i> For reporters: <a href="tel:1-213-244-2442" className="text-info">1-213-244-2442</a></p>
    </div>

    {/* Fax Section */}
    <div className="contact-section contact-form-fax-section bg-light p-3 mb-4 rounded">
      <h3 className="text-primary">Fax Numbers</h3>
      <ul className="list-unstyled">
        <li><i className="fas fa-fax"></i> SoCalGas: <a href="fax:1-909-305-8261" className="text-info">1-909-305-8261</a></li>
        <li><i className="fas fa-fax"></i> Credit Department: <a href="fax:1-818-701-3950" className="text-info">1-818-701-3950</a></li>
      </ul>
    </div>

    <div className="contact-section contact-form-mailing-section bg-white p-3 mb-4 rounded shadow-sm">
      <h3 className="text-primary">Write to Us</h3>
      <p>Centralized Correspondence:<br />
         P.O. Box 1626,<br /> Monterey Park, CA 91754-8626</p>

      <h4 className="text-primary mt-3">Mail Your Bill Payments:</h4>
      <p>Residential: P.O. Box C, Monterey Park, CA 91756<br />
         Business: ML 711D, 1801 S. Atlantic Blvd., Monterey Park, CA 91754</p>
    </div>

    {/* Payment Location Section */}
    <div className="contact-section contact-form-payment-section bg-light p-3 mb-4 rounded">
      <h3 className="text-primary">Find a Payment Location</h3>
      <p>You can view our available payment locations by clicking <a href="#" className="text-info">here</a>.</p>
    </div>
    <div className="contact-section contact-form-advertising-section bg-white p-3 mb-4 rounded shadow-sm">
      <h3 className="text-primary">Advertising</h3>
      <p>For marketing inquiries, contact:<br />
        <a href="https://www.stfrd.com" target="_blank" className="text-info">STFRD/Stafford</a></p>
    </div>
          
        </div>

        {/* Fixed Map Section */}
        <div className="contact-map flex-shrink-0">
          <ContactMap />
        </div>
      </section>
    </div>
  );
};

export default ContactUs;
